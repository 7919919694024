<template>
  <div id="app" @click="dropShow = false">
    <div class="center pin" :style="cssProps">
      <div ref="logo">
        <router-link to="/">
          <img :src="logo" alt="img" class="logo" />
        </router-link>
      </div>
      <div class="info" @click.stop="dropShow = !dropShow" v-if="participant && !initializing">
        <div class="sup">Currently Viewing</div>
        <div class="row">
          <div
            class="fill"
            v-if="participant.name"
            @click.stop="
              $router.push('/profile/' + participant.id);
              dropShow = false;
            "
          >
            Welcome, {{ name }}
          </div>
          <div class="big" v-if="participant.name">
            <span>R</span>oba-<span>P</span>alooza <span :class="{ rn: true, small: year.roman_numeral.length > 2 }">{{ year.roman_numeral }}</span>
          </div>
        </div>
        <transition name="menu">
          <div class="year_select" v-if="dropShow">
            <div
              v-for="y in yearlist"
              :key="y.id"
              @click="setYear(y)"
              :style="{ color: y.color, border: y.id == year.id ? `2px solid ${y.color}` : '0px' }"
              :class="{ current: y.current, year_entry: true }"
            >
              RobaPalooza {{ y.roman_numeral }} <span>{{ y.date }}</span>
            </div>
          </div>
        </transition>
      </div>

      <div class="message-blocks" ref="message_block">
        <small v-if="message" class="alert_message" ref="ref_alert_message">{{ message }}</small>
        <small v-if="error" class="alert_error" ref="ref_alert_error">{{ error }}</small>
      </div>
      <router-view v-if="!initializing" />
      <div id="loader" v-else>
        <loader />
      </div>
    </div>
    <div class="footer"><span>&copy;</span> {{ currentYear }} <span>R</span>oba-<span>P</span>alooza</div>
  </div>
</template>
<script>
// import { defineComponent } from '@vue/composition-api'
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "Robapalooza",
  data() {
    return {
      currentYear: new Date().getFullYear(), // 202
      dropShow: false,
    };
  },
  methods: {
    ...mapActions(["setYear"]),
  },
  watch: {
    error: {
      handler(newValue) {
        const el = this.$refs.message_block;
        if (el && newValue) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      },
    },
    message: {
      handler(newValue) {
        const el = this.$refs.message_block;
        if (el && newValue) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      },
    },
  },
  computed: {
    ...mapState(["year", "years", "message", "error", "participant"]),
    ...mapGetters(["initializing", "isAdmin"]),
    logo() {
      return `${process.env.VUE_APP_IMG_URL}img.jpg`;
    },
    cssProps() {
      return {
        "--tintcolor": this.year.color,
      };
    },
    name() {
      if (!this.participant || !this.participant.name) {
        return "";
      }
      return this.participant.name.split(" ")[0];
    },
    yearlist() {
      return this.years.filter((year) => {
        if (year.active == "0" && !this.isAdmin) {
          return false;
        }
        return true;
      });
    },
  },
};
</script>

<style>
div#loader {
  display: flex;
  justify-content: center;
}
html,
body {
  margin: 0;
  height: 100%;
  font-family: "Permanent Marker";
  font-size: 30pt;
  max-width: 100%;
  overflow-x: hidden;
}

h6,
h4 {
  margin: 10px 0px;
}
.left {
  float: left;
}
.anchor {
  clear: both;
}
.year_select .year_entry.current::after {
  content: "current";
  color: white;
  transform: rotate(-90deg);
  position: absolute;
  font-size: 11px;
  top: 15px;
  left: -10px;
}
.year_select {
  position: absolute;
  width: 180px;
  right: 0px;
  top: 30px;
  background: black;
  z-index: 1000;
  padding: 20px 10px 10px;
  border-radius: 10px;
}
.year_select .year_entry {
  font-size: 22px;
  padding: 5px 0px;
  text-align: center;
  border-radius: 6px;
  position: relative;
}
.year_select .year_entry span {
  font-size: 18px;
  color: #bbb;
  display: block;
}
.year_select .year_entry:hover {
  background-color: #333;
}
.info {
  background: black;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  font-family: "Staatliches";
  font-size: 10pt;
  letter-spacing: 1px;
  line-height: 16pt;
  position: relative;
  cursor: pointer;
}
.info .row {
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: space-between;
}
.info .sup {
  position: absolute;
  right: 70px;
  top: -16px;
  font-size: 8pt;
  color: black;
}
.info .small {
  line-height: 10px;
}
.info .big {
  font-size: 20px;
  padding-right: 30px;
}
.info span {
  font-size: 24px;
}
.info .rn {
  font-size: 30px;
  font-family: "Permanent Marker";
  width: 50px;
  height: 50px;
  position: absolute;
  top: -10px;
  right: -20px;
  background-color: white;
  border: 6px solid var(--tintcolor);
  border-radius: 200px;
  color: var(--tintcolor);
  display: block;
  line-height: 50px;
  text-align: center;
  z-index: 1001;
}
.info .rn.small {
  font-size: 18px;
}
a.admin:hover {
  color: #969;
}
#error {
  font-family: "Staatliches";
  font-size: 10pt;
  letter-spacing: 1px;
  color: #a33;
  text-align: center;
}
#loader {
  display: block;
  margin: 20px auto;
}
#message {
  font-family: "Staatliches";
  font-size: 10pt;
  letter-spacing: 1px;
  color: #3a3;
  text-align: center;
}
.spinner {
  margin: 0px auto;
  display: block;
}
.flex {
  display: flex;
}
.flex a {
  margin-right: 20px;
}
.center {
  /* position: fixed; */
  /* left: 50%; */
  /* top: 50%; */
  /* margin-left: -320px; */
  /* margin-top: -300px; */
  padding-bottom: 80px;
  max-width: 640px;
  margin: 20px auto;
}
.center-content {
  text-align: center;
}
ul {
  font-family: "Staatliches";
  letter-spacing: 1px;
  font-size: 18pt;
}
.note {
  font-family: "Staatliches";
  font-size: 10pt;
  letter-spacing: 1px;
  color: gray;
  margin: 25px 0px 5px 0px;
}
.wide {
  width: 360px;
}
.center.wide {
  margin-left: -180px;
}
.tight {
  line-height: 40px;
}
.pin {
  /* position: relative;
  top: 320px; */
}
.item {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16pt;
  letter-spacing: 1px;
}
.small {
  font-size: 14pt;
  letter-spacing: 1px;
  line-height: 20px;
}
small {
  font-family: "Staatliches";
  font-size: 18pt;
}
.item div {
  width: 537px;
  display: inline-block;
}
.item.small div {
  width: 305px;
}
input {
  font-size: 16px;
  font-family: "Permanent Marker";
  border: 1px solid black;
  width: 300px;
}
input.small {
  width: 60px;
}
.footer {
  background-color: black;
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  text-align: center;
  font-family: 30pt;
  font-size: 18pt;
  padding-top: 10px;
  letter-spacing: -1px;
  font-family: "Staatliches";
}
a {
  display: block;
  color: black;
  text-decoration: none;
}
a:hover {
  color: #a00;
}
a.disabled,
a.disabled:hover {
  color: #aaa;
  cursor: not-allowed;
}

.footer span {
  font-size: 20pt;
}
.stat {
  margin-top: 20px;
  font-family: "Staatliches";
}
.logo {
  width: 317px;
  height: 250px;
  margin: 0px auto 15px auto;
  display: block;
}
@media (max-width: 700px) {
  .logo {
    width: 158px;
    height: 125px;
  }
  .center {
    /* margin-top: -255px; */
  }
  .pin {
    /* margin-top: -310px; */
  }
  .center {
    /* margin-left: -158px;
    margin-top: -300px; */
    max-width: 330px;
  }
  .item {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16pt;
    letter-spacing: 1px;
  }
  .small {
    font-size: 14pt;
    letter-spacing: 1px;
    line-height: 20px;
  }
  .item div {
    width: 235px;
    display: inline-block;
  }
  .item.small div {
    width: 105px;
  }
  input {
    font-size: 16px;
    font-family: "Permanent Marker";
    border: 1px solid black;
    width: 200px;
  }
  input.small {
    width: 60px;
  }
}
.button {
  float: left;
  /* display: inline-block; */
  height: 50px;
  margin: 5px;
  border: 3px solid black;
  margin-top: 20px;
  font-family: "Staatliches";
  font-size: 30px;
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
  background: white;
  color: black;
  border-radius: 5px;
  cursor: pointer;
}
.button img {
  position: relative;
  top: -6px;
}
.button.big {
  padding: 0px 15px;
  display: block;
  float: none;
}

.button.selected {
  background: black;
  color: white;
}

strong {
  font-size: 14pt;
}

input[type="checkbox"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  outline: none;
  font-size: inherit;
  cursor: pointer;
  width: 1em;
  height: 1em;
  background: white;
  border-radius: 0.25em;
  border: 3px solid black;
  top: 5px;
  left: 10px;
  position: relative;
}

/* 
input[type="checkbox"]:checked {
  background: #88a776; 
} 
*/

input[type="checkbox"]:checked:after {
  content: "X";
  position: absolute;
  font-size: 90%;
  top: -10px;
  right: 6px;
}

.warning {
  text-align: center;
  color: #900;
  padding: 10px 20px;
  border: 1px solid #900;
  border-radius: 6px;
  background-color: #faa;
  display: none;
  font-family: "Staatliches";
  font-size: 18pt;
}

.warning:hover {
  color: #400;
  border: 1px solid #400;
}
a {
  cursor: pointer;
}
a:hover {
  color: var(--tintcolor);
}
.tintcolor {
  color: var(--tintcolor);
}
.alert_error {
  border: 4px solid #800;
  padding: 20px;
  margin: 5px auto 0px;
  display: block;
  color: #800;
  text-align: center;
}
.alert_message {
  border: 4px solid #080;
  padding: 20px;
  margin: 0px auto;
  display: block;
  color: #080;
  text-align: center;
}
#loader .spinner {
  margin: 10px auto;
}

.menu-enter-active,
.menu-leave-active {
  transition: opacity 0.25s ease;
}

.menu-enter-from,
.menu-leave-to {
  opacity: 0;
}
</style>
