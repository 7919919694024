<template>
  <div class="home">
    <div class="admin" v-if="isAdmin">
      <router-link to="people">> <span>People</span></router-link>
      <router-link to="message">> <span>Message</span></router-link>
      <router-link to="entries">> <span>Entries</span></router-link>
      <router-link to="status">> <span>Status</span></router-link>
      <router-link to="years">> <span>Years</span></router-link>
    </div>
    <router-link :to="`profile/${participant.id}`" v-if="isLoggedIn">> My Info</router-link>
    <router-link to="event">> Event Info</router-link>
    <router-link to="about">> About</router-link>
    <router-link to="wof" :class="{ disabled: statusFor(statusStrings.gallery) !== true }">> Wall of Fame</router-link>
    <router-link to="register" v-if="isLoggedIn" :class="{ disabled: statusFor(statusStrings.register) !== true }"
      >> Register for Roba {{ year.roman_numeral }}</router-link
    >
    <router-link to="smack" v-if="isLoggedIn" :class="{ disabled: statusFor(statusStrings.smack) !== true }">> Smackboard </router-link>
    <router-link to="vote" v-if="isLoggedIn && (statusFor(statusStrings.vote_submit) === true || isAdmin)"
      >> Vote<span class="note padleft red" v-if="statusFor(statusStrings.vote_submit) !== true">disabled</span></router-link
    >
    <router-link to="bis" v-if="isLoggedIn && (statusFor(statusStrings.bis_submit) === true || isAdmin)"
      >> Best In Show<span class="note padleft red" v-if="statusFor(statusStrings.bis_submit) !== true">disabled</span>
    </router-link>
    <router-link to="standings" v-if="isLoggedIn && (statusFor(statusStrings.vote_view) === true || isAdmin)"
      >> Standings<span class="note padleft red" v-if="statusFor(statusStrings.vote_view) !== true">disabled</span>
    </router-link>

    <router-link to="login" v-if="!isLoggedIn">> Login</router-link>
    <a v-on:click="setParticipant(false)" v-if="isLoggedIn">> Logout</a>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "Home",
  computed: {
    ...mapGetters(["statusFor", "isAdmin"]),
    ...mapState(["year", "participant", "statusStrings"]),
    isLoggedIn() {
      return this.participant.name;
    },
  },
  methods: {
    ...mapMutations(["setParticipant"]),
  },
  components: {},
};
</script>
<style scoped>
.nopad {
  margin: 0px;
}
.link {
  cursor: pointer;
}
.link:hover {
  color: var(--tintcolor);
}
.disabled {
  opacity: 0.5;
}
.padleft {
  margin-left: 10px;
  position: relative;
  top: -5px;
}
.red {
  color: darkred;
}
.big {
  font-size: 18pt;
}

.admin {
  border: 6px solid var(--tintcolor);
  border-radius: 10px;
  padding: 5px 20px;
  margin-top: 20px;
  position: relative;
  color: var(--tintcolor);
}
.admin a {
  color: var(--tintcolor);
}
.admin::before {
  content: "admin";
  font-family: "Staatliches";
  background: white;
  font-size: 18pt;
  top: -18px;
  left: 10px;
  position: absolute;
  padding: 0px 6px;
}
</style>
