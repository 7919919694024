import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Gravatar from "./components/Gravatar";
import Loader from "@/components/Loader.vue";

const app = createApp(App);
app.component("loader", Loader).component("grav", Gravatar).use(store).use(router).mount("#app");

//Location Management
var slug = window.location.hostname.split(".")[0];
var defaults = ["", "www", "robapalooza", "localhost"];
if (slug != "localhost") {
  if (defaults.includes(slug)) {
    console.log("Cookie:", document.cookie);
    slug = localStorage.getItem("location");
    if (slug && !defaults.includes(slug)) {
      console.log("Redirecting to", slug);
      window.location = `https://${slug}.robapalooza.com` + window.location.pathname + window.location.search;
    }
  }
}
