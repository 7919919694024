import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/tos",
    name: "Terms of Service",
    component: () => import(/* webpackChunkName: "TOS" */ "../views/TOS.vue"),
  },
  {
    path: "/event",
    name: "Event Info",
    props: true,
    component: () => import(/* webpackChunkName: "Event Info" */ "../views/Event.vue"),
  },
  {
    path: "/location",
    name: "Location",
    component: () => import(/* webpackChunkName: "locations" */ "../views/Location.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy Policy",
    component: () => import(/* webpackChunkName: "Privacy Policy" */ "../views/Privacy.vue"),
  },
  {
    path: "/profile/:id",
    name: "Profile",
    component: () => import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
    props: true,
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import(/* webpackChunkName: "register" */ "../views/Register.vue"),
    meta: { checkStatus: "register", login: true },
  },
  {
    path: "/reset",
    name: "Reset",
    component: () => import(/* webpackChunkName: "reset" */ "../views/Reset.vue"),
  },
  {
    path: "/smack",
    name: "Smack",
    component: () => import(/* webpackChunkName: "smack" */ "../views/Smack.vue"),
    meta: { checkStatus: "smack" },
  },
  {
    path: "/vote",
    name: "Vote",
    component: () => import(/* webpackChunkName: "vote" */ "../views/Vote.vue"),
    meta: { checkStatus: "vote_submit", login: true },
  },
  {
    path: "/bis",
    name: "BIS",
    component: () => import(/* webpackChunkName: "bis" */ "../views/BIS.vue"),
    meta: { checkStatus: "bis_submit", login: true },
  },
  {
    path: "/people/:filter?",
    name: "People",
    props: true,
    component: () => import(/* webpackChunkName: "people" */ "../views/People.vue"),
    meta: { admin: true },
  },
  {
    path: "/message",
    name: "Message",
    props: true,
    component: () => import(/* webpackChunkName: "people" */ "../views/Message.vue"),
    meta: { admin: true },
  },
  {
    path: "/entries",
    name: "Entries",
    component: () => import(/* webpackChunkName: "entries" */ "../views/Entries.vue"),
    meta: { admin: true },
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import(/* webpackChunkName: "forgot" */ "../views/Forgot.vue"),
  },
  {
    path: "/standings",
    name: "Standings",
    component: () => import(/* webpackChunkName: "standings" */ "../views/Standings.vue"),
    meta: { checkStatus: "vote_view" },
  },
  {
    path: "/status",
    name: "Status",
    component: () => import(/* webpackChunkName: "status" */ "../views/Status.vue"),
    meta: { admin: true },
  },
  {
    path: "/changeyear",
    name: "ChangeYear",
    component: () => import(/* webpackChunkName: "changeyear" */ "../views/ChangeYear.vue"),
    meta: { login: true },
  },
  {
    path: "/years",
    name: "Years",
    component: () => import(/* webpackChunkName: "years" */ "../views/Years.vue"),
    meta: { admin: true },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    beforeEnter: (next) => {
      if (store.state.participant) {
        next({ name: "Home" });
      }
      return true;
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import(/* webpackChunkName: "logout" */ "../views/Logout.vue"),
    beforeEnter: (next) => {
      if (!store.state.participant) {
        next({ name: "Home" });
      }
      return true;
    },
  },
  {
    path: "/wof",
    name: "Wall of Fame",
    component: () => import(/* webpackChunkName: "wof" */ "../views/WOF.vue"),
    meta: { checkStatus: "gallery" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

/**
 *     this.getYear();
    this.getStatus();
    this.getParticipant();
 */
const validate = function (to) {
  var message = [];
  var participant = store.getters.participant;
  if (to.meta.checkStatus) {
    var msg = store.getters.statusFor(to.meta.checkStatus);
    if (msg !== true) {
      if (store.getters.isAdmin) {
        store.commit("setError", msg);
      } else {
        message.push(msg);
      }
    }
  }
  if (to.meta.login && !participant) {
    message.push("You must be logged in to see this page");
  }
  if (to.meta.admin && (!participant || !Number(participant.admin))) {
    message.push("You must be an admin to access that resource");
  }
  return message.join(" • ");
};

router.beforeEach((to, from, next) => {
  if (to.name == "Home") {
    var slug = window.location.hostname.split(".")[0];
    var defaults = ["", "www", "robapalooza"];
    if (defaults.includes(slug)) {
      next({ name: "Location" });
      return;
    }
  }
  if (!to.params.ignoreClear) {
    store.commit("clearMessages");
  }
  if (!store.getters.year) {
    store.dispatch("init").then(() => {
      var msg = validate(to);
      if (msg == "") {
        next();
      } else {
        next({ name: "Home", params: { ignoreClear: true } });
        store.commit("setError", msg);
      }
    });
  } else {
    // store.dispatch("getStatus").then(() => {
    var msg = validate(to);
    if (msg == "") {
      next();
    } else {
      next({ name: "Home", params: { ignoreClear: true } });
      store.commit("setError", msg);
    }
    // })
  }
});

export default router;
