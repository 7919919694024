import store from '@/store';
import axios from 'axios';

let baseURL = process.env.VUE_APP_DOMAIN;

// console.log(baseURL)

const api = {
  baseURL: baseURL,
  ax: axios.create({
    baseURL: baseURL + '/',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  }),
  upload(url, file, onUploadProgress) {
    let data = new FormData();
    data.append('file', file);
    return this.ax.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  },
  get(url, params, headers) {
    var p = { params: { year_id: store.getters.year.id, t: Date.now(), ...params } };
    if (headers) {
      p.headers = headers;
    }
    return this.ax.get(url, p);
  },
  post(url, data, params) {
    var p = { params: { year_id: store.getters.year.id, ...params } };
    return this.ax.post(url, data, p);
  },
  patch(url, data, params) {
    var p = { params: { year_id: store.getters.year.id, ...params } };
    return this.ax.put(url, data, p);
  },
  put(url, data, params) {
    var p = { params: { year_id: store.getters.year.id, ...params } };
    return this.ax.put(url, data, p);
  },
  delete(url, data, params) {
    var p = { params: { year_id: store.getters.year.id, ...params } };
    return this.ax.delete(url, { data }, p);
  }
};
export default api;
