import { reactive } from "vue";
import { createStore } from "vuex";
import api from "@/api";
import Pusher from "pusher-js";

// Pusher.logToConsole = true

const pusher = new Pusher("5db7b2cd665236a9332f", { cluster: "mt1" });

//Determine if the page has gone into the background, or the phone has locked
(function () {
  var timestamp = new Date().getTime();

  function checkResume() {
    var current = new Date().getTime();
    if (current - timestamp > 4000) {
      var event = document.createEvent("Events");
      event.initEvent("resume", true, true);
      document.dispatchEvent(event);
    }
    timestamp = current;
  }

  window.setInterval(checkResume, 1000);
})();

// Create a new store instance.
export default createStore({
  state() {
    return {
      message: false,
      error: false,
      year: false,
      years: [],
      types: [],
      participant: {},
      status: [],
      loading: [],
      socket_id: false,
      statusStrings: {
        vote_view: "vote_view",
        vote_submit: "vote_submit",
        bis_submit: "bis_submit",
        register: "register",
        smack: "smack",
        gallery: "gallery",
        edit_entries: "edit_entries",
      },
    };
  },
  getters: {
    loading(state) {
      return state.loading.length != 0;
    },
    initializing(state) {
      return state.loading.includes("init") || state.loading.includes("status") || state.loading.includes("required");
    },
    participant(state) {
      return state.participant;
    },
    /**
     * statusFor
     * @param type
     * @returns true or status message string
     */
    statusFor: (state) => (type) => {
      var fstatus = state.status.filter((item) => {
        return item.name == type;
      });
      if (fstatus.length == 0) {
        console.error("Unable to determine status for " + type);
        return "Unable to determine status for" + type;
      }
      if (fstatus[0].status_years.length == 0) {
        console.warn("No status_years for", fstatus[0]);
        return "No status set";
      }
      return fstatus[0].status_years[0].active ? true : fstatus[0].message;
    },
    isKeyLoading: (state) => (key) => {
      return state.loading.includes(key);
    },
    year(state) {
      return state.year;
    },
    isAdmin(state) {
      return state.participant && Number(state.participant.admin);
    },
  },
  mutations: {
    startLoading(state, key) {
      if (!state.loading.includes(key)) {
        state.loading.push(key);
      }
    },
    stopLoading(state, key) {
      const index = state.loading.indexOf(key);
      if (index > -1) {
        state.loading.splice(index, 1);
      }
    },
    setMessage(state, payload) {
      state.error = false;
      state.message = payload;
    },
    setError(state, payload) {
      state.message = false;
      state.error = payload;
    },
    clearMessages(state) {
      state.message = false;
      state.error = false;
    },
    setTypes(state, payload) {
      state.types = reactive(payload);
    },
    setYears(state, payload) {
      state.years = reactive(payload);
    },
    setYear(state, payload) {
      if (payload) {
        state.year = reactive(payload);
        localStorage.year = JSON.stringify(payload);
      }
    },
    setParticipant(state, payload) {
      if (payload) {
        state.participant = reactive(payload);
        if (payload.authorizations && payload.authorizations.length && payload.authorizations[0].token) {
          localStorage.auth_token = payload.authorizations[0].token;
        }
      } else {
        state.participant = false;
        localStorage.auth_token = null;
      }
    },
    setStatus(state, payload) {
      if (payload) {
        state.status = reactive(payload);
        // localStorage.year = JSON.stringify(year)
      } else {
        console.log("clearing status");
        state.status = reactive([]);
        // localStorage.client = null
      }
    },
    setIndividualStatus(state, payload) {
      if (payload) {
        const idx = state.status.findIndex((i) => i.id == payload.id);
        if (idx != -1) {
          state.status[idx] = reactive(payload);
        } else {
          console.warn("Could not find index of status: ", payload);
        }
      }
    },
  },
  actions: {
    init({ commit, dispatch, state }) {
      addEventListener("resume", () => {
        dispatch("getStatus");
      });
      commit("startLoading", "init");
      var params = {};
      if (localStorage.auth_token) {
        params["Auth-Token"] = localStorage.auth_token;
      }
      pusher.connection.bind("connected", () => {
        state.socket_id = pusher.connection.socket_id;
      });

      let channel = pusher.subscribe("status");
      channel.bind("update", (data) => {
        if ((data.status_years.length, data.status_years[0].year_id == state.year.id)) {
          console.log("Pusher is updating status", data);
          commit("setIndividualStatus", data);
        }
      });

      return api
        .get("init", null, params)
        .then((res) => {
          commit("setParticipant", res.data.participant);
          const local = localStorage.year ? JSON.parse(localStorage.year) : false;
          if (local && local.location_id == res.data.location.id) {
            const year = res.data.years.find((y) => y.id == local.id);
            commit("setYear", year ?? local);
          } else {
            commit("setYear", res.data.year);
          }
          commit("setStatus", res.data.statuses);
          commit("setTypes", res.data.entry_types);
          commit("setYears", res.data.years);
          commit("stopLoading", "init");
        })
        .catch((e) => {
          console.error(e);
          commit("setError", "No response from server");
          commit("stopLoading", "init");
        });
    },
    login({ commit }, data) {
      commit("startLoading", "login");
      return api
        .post("login", data)
        .then((res) => {
          commit("setParticipant", res.data.participant);
          commit("stopLoading", "login");
        })
        .catch((e) => {
          // console.log(e);
          commit("setError", e.response.data.message);
          commit("stopLoading", "login");
        });
    },
    newParticipant({ commit }, data) {
      commit("startLoading", "login");
      return api
        .post("participants", data)
        .then((res) => {
          commit("setParticipant", res.data.participant);
          commit("stopLoading", "login");
        })
        .catch((e) => {
          // console.log(e);
          commit("setError", e.response.data.message);
          commit("stopLoading", "login");
        });
    },
    setYear({ commit, dispatch }, year) {
      commit("clearMessages");
      commit("setYear", year);
      dispatch("getStatus");
    },
    getYear({ commit }, year) {
      var options = {};
      if (year) {
        options = { year: year };
      }
      commit("startLoading", "year");
      api
        .get("year", options)
        .then((res) => {
          commit("setYear", res.data.result);
          commit("stopLoading", "year");
        })
        .catch((e) => {
          // console.log(e);
          commit("setError", e.response.data.message);
          commit("stopLoading", "year");
        });
    },
    getStatus({ commit }) {
      commit("startLoading", "status");
      return api
        .get("status")
        .then((res) => {
          let result = res.data;
          commit("setStatus", result);
          commit("stopLoading", "status");
        })
        .catch((e) => {
          // console.log(e);
          commit("setError", e.response.data.message);
          commit("stopLoading", "status");
        });
    },
    /**
     * getParticipant loads participant from token stored in localstorage
     */
    getParticipant({ commit }) {
      var headers = {};
      if (localStorage.auth_token) {
        headers["Auth-Token"] = localStorage.auth_token;
      }
      commit("startLoading", "getParticipant");
      api
        .get("participant", null, headers)
        .then((res) => {
          commit("setParticipant", res.data.participant);
          commit("stopLoading", "getParticipant");
        })
        .catch((e) => {
          // console.log(e);
          commit("setError", e.response.data.message);
          commit("stopLoading", "participant");
        });
    },
    /**
     * updates the password for the participant
     */
    updateParticipantPassword({ commit }, data) {
      commit("startLoading", "participant");
      return api
        .patch("participants/password", data)
        .then((res) => {
          commit("setParticipant", res.data.participant);
          commit("stopLoading", "participant");
        })
        .catch((e) => {
          // console.log(e.response.data);
          // console.log(e);
          commit("setError", e.response.data.message);
          commit("stopLoading", "participant");
        });
    },
    /**
     * updates the participant
     */
    updateParticipant({ commit }, data) {
      commit("startLoading", "participant");
      return api
        .patch("participants", data)
        .then((res) => {
          commit("setParticipant", res.data.participant);
          commit("stopLoading", "participant");
        })
        .catch((e) => {
          // console.log(e.response.data);
          // console.log(e);
          commit("setError", e.response.data.message);
          commit("stopLoading", "participant");
        });
    },
  },
});
