<template>
  <div class="grav" :style="{ height: size + 'px', width: size + 'px' }" :class="{ circle, inline }">
    <img :src="url" :height="size" :width="size" />
  </div>
</template>
<script>
import md5 from 'md5';

export default {
  name: 'gravatar',
  props: {
    email: { type: String, required: true },
    size: {
      type: Number,
      default: 40
    },
    circle: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    url() {
      const hash = md5(this.email.toLowerCase());
      const size = this.size * 2;
      return `https://www.gravatar.com/avatar/${hash}?d=mp&s=${size}`;
    }
  }
};
</script>
<style scoped>
.circle {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inline {
  display: inline-block;
}
.circle img {
  display: block;
}
.inline img {
  display: inline;
}
</style>
